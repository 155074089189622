
import React, { Suspense, useEffect } from 'react';
import Routes from "./routes";
import "./config/i18n";
import { etisalatHost } from './config/enums';
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import * as Sentry from "@sentry/react";
const EtisalatTheme = React.lazy(() => import('./Themes/EtisalatTheme.js'));


const growthbook = new GrowthBook({

  apiHost: process.env.REACT_APP_GROWTH_BOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTH_BOOK_CLIENT_KEY,
  enableDevMode: true
});

const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = etisalatHost;
  return (
    <>
      <Suspense fallback={<div>Loading.......</div>}>
        {CHOSEN_THEME && <EtisalatTheme />}
      </Suspense>
      {children}
    </>
  )
}
function App() {
  useEffect(() => {
    growthbook.init({ streaming: true })

      .catch(() => {
        Sentry.captureException("Error: GrowthBook Missing clientKey")
      })
  }, []);
  return (
    <GrowthBookProvider growthbook={growthbook}>
      <ThemeSelector>
        <Routes />
      </ThemeSelector>
    </GrowthBookProvider>
  );
}
export default App;
